<app-hero
  [bgUrl]="'/assets/szaraz-gyulladt-viszketo-bor/header.webp'"
  [bgUrlM]="'/assets/szaraz-gyulladt-viszketo-bor/header-m.webp'"
  [header]="'Száraz, gyulladt,<br>viszkető bőr?'"
  [text]="'Mi állhat a háttérben?'"
></app-hero>
<section id="szaraz-gyulladt-viszketo-bor">
  <div class="container">
    <div class="info-box">
      <p>
        A száraz, gyulladt, viszkető bőr mögött többféle kórkép állhat, és
        ezeket szabad szemmel nehéz megkülönböztetni egymástól. A pontos
        diagnózishoz szakorvosi vizsgálatra van szükség.
      </p>
      <p>
        <strong>
          Ami viszont a gyulladásos, viszkető bőrgyógyászati kórképekben közös,
          az a bőr felső rétegét érintő gyulladás, amely szabad gyökök
          felszaporodásával jár együtt.
        </strong>
      </p>
    </div>
    <h2>Mik azok a szabad gyökök?</h2>
    <div class="d-flex">
      <div class="text">
        <p>
          A <strong>szabad gyökök</strong> párosítatlan elektronnal rendelkező,
          rendkívül reakcióképes molekulák, amelyet az emberi szervezet azért
          termel, hogy a benne levő kórokozókkal felvegye a harcot. Egy
          egészséges szervezetben a szabad gyökök az őket semlegesítő
          antioxidánsokkal <strong>egyensúlyban vannak</strong>. Azonban, ha ez
          az egyensúly felborul, a szabad gyökök az egészséges sejteket is
          károsíthatják. Ilyen esetekben
          <strong
            >az antioxidánsok pótlása segíthet az egyensúly
            helyreállításában.</strong
          >
        </p>
        <p>
          <strong
            >Nem mindegy azonban, hogy milyen tünetek esetén milyen
            antioxidánshoz nyúlunk.</strong
          >
          Minden antioxidánsnak megvan a szervezeten belüli saját, speciális
          szerepe, amelyet olykor más antioxidánsokkal együttműködve lát el.
        </p>
      </div>
      <div class="img pl-2">
        <img
          src="/assets/szaraz-gyulladt-viszketo-bor/szabad-gyokok-2.webp"
          alt=""
        />
      </div>
    </div>
    <div class="d-flex">
      <div class="img pr-2">
        <img src="/assets/szaraz-gyulladt-viszketo-bor/glutation.webp" alt="" />
      </div>
      <div class="text">
        <p>
          <strong
            >A kutatások azt találták, hogy bőrgyulladás esetén kiemelt szerepe
            van a glutationnak, mint antioxidánsnak.</strong
          >
          A glutationt a szervezet maga is képes előállítani, ha rendelkezésre
          állnak az „építőkövek”. Több esetben azonban pl. sérült enzimműködés,
          felszívódási zavarok vagy helytelen táplálékbevitel esetén
          glutationhiány alakulhat ki. A glutationhiány miatt a szervezet szabad
          gyököket semlegesítő képessége korlátozott lehet, és a fent említett,
          szabad gyökök túlsúlyával jellemezhető, gyulladásos állapot tartóssá
          válhat.
        </p>
      </div>
    </div>
    <h2>Tudjon meg többet az alábbi betegségekről</h2>
    <div class="more">
      <div class="d-flex">
        <div class="more-box ekcema">
          <a routerLink="/mi-az-ekcema">Mi az az ekcéma?</a>
        </div>
        <div class="more-box pikkelysomor">
          <a routerLink="/mi-a-pikkelysomor">Mi az a pikkelysömör?</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="footer">
  <div class="footer-top">
    <div class="container">
      <small
        >A Glutabalance Psorio Forte és a Glutabalance Psorio Caps
        étrend-kiegészítő termékek.<br />Étrend-kiegészítő, nem helyettesíti a
        vegyes étrendet és az egészséges életmódot.</small
      >
      <div class="action-box">
        <h3>Kipróbálom a Glutabalance termékeit!</h3>
        <a
          [routerLink]="['/vasarlasi-informaciok']"
          routerLinkActive="router-link-active"
          >Tovább <i class="fa fa-chevron-right fa-xs" aria-hidden="true"></i
        ></a>
      </div>
    </div>
  </div>
</section>
