import { Component, OnInit } from "@angular/core";
import { User } from "src/app/models/user.model";
import { TermekkiprobalasService } from "src/app/services/termekkiprobalas.service";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MaxUsers } from 'src/app/config/maxUsers.enum';
import { CodesService } from 'src/app/services/codes.service';
import { Code } from 'src/app/models/code.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmailsService } from "src/app/services/emails.service";

@Component({
  selector: "app-termekkiprobalas",
  templateUrl: "./termekkiprobalas.component.html",
  styleUrls: ["./termekkiprobalas.component.scss"],
})
export class TermekkiprobalasComponent implements OnInit {

  isLoadingPsw: boolean;
  isRequiredPsw: boolean;
  data;
  inputData: string = '';

  productTesting = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    telephone: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    privacyPolicy: new FormControl(false, [Validators.requiredTrue]),
    termsAndConditions: new FormControl(false, [Validators.requiredTrue])
  });

  buttonInnerHtml: string;
  btnLoading: boolean;
  endpoint: string;
  isFreeCodes: boolean;
  maxUsers = MaxUsers.CapsUsers + MaxUsers.ForteUsers;
  whichUser: string;
  usedEmailList: string[];
  isLoading: boolean;
  codes: Code[];

  constructor(
    private termekkiprobalasService: TermekkiprobalasService,
    private emailsService: EmailsService,
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {
    this.buttonInnerHtml = 'Küldés <i class="fas fa-paper-plane"></i>';
    this.btnLoading = false;
    this.endpoint = "/assets/php/sendForm.php";
    this.isLoadingPsw = true;
    this.isRequiredPsw = true;
  }
  
  ngOnInit(): void {
    // this.getUsers();
    this.termekkiprobalasService.validateConfigPsw().subscribe((data) => {
      if (data.isRequiredPsw) {
        this.data = data;
        this.isRequiredPsw = true;
        this.isLoadingPsw = false;
      } else {
        this.isRequiredPsw = false;
        this.isLoadingPsw = false;
      }
    })
  }

  checkPsw(data: string) {
    if (data === this.data.psw) {
      this.isRequiredPsw = false;
    }
  }

  apply(): void {
    this.btnLoading = true;
    this.buttonInnerHtml = '<i class="fa fa-spinner fa-pulse fa-fw"></i><span class="sr-only">Loading...</span>';

    const user: User = {
      name: this.productTesting.get('name').value,
      email: this.productTesting.get('email').value,
      telephone: this.productTesting.get('telephone').value,
      address: this.productTesting.get('address').value
    };

    // this.termekkiprobalasService.createUser(user).then((res: any) => {
    //   if (res.body.status === 'OK') {
    //     this.emailsService.newRegistration({...user, id: res.body.userId}).subscribe((res) => {
    //       this.buttonInnerHtml = '<i class="fa fa-check"></i>';
    //       this.openSnackBar('Sikeres regisztráció!');
    //       setTimeout(() => {
    //         this.router.navigate(['/sikeres-regisztracio']);
    //       }, 2000);
    //     });
    //   } else {
    //     this.buttonInnerHtml = '<i class="fa fa-times"></i>';
    //     this.openSnackBar(`${res.body.message}`);
    //     setTimeout(() => {
    //       this.buttonInnerHtml = 'Küldés <i class="fas fa-paper-plane"></i>';
    //       this.btnLoading = false;
    //     }, 2000);
    //   }
    // }).catch((err) => {
    //   console.error(err);
    //   this.buttonInnerHtml = '<i class="fa fa-times"></i>';
    //   this.openSnackBar('Sikertelen regisztráció!');
    //   setTimeout(() => {
    //     this.buttonInnerHtml = 'Küldés <i class="fas fa-paper-plane"></i>';
    //     this.btnLoading = false;
    //   }, 2000);
    // });
    
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, 'Rendben', {duration: 5000});
  }

  // getUsers(): void {
  //   this.isLoading = true;
  //   let subs = this.termekkiprobalasService.getUsers().subscribe(users => {
  //     subs.unsubscribe();
  //     if (users.length >= MaxUsers.CapsUsers + MaxUsers.ForteUsers) {
  //       this.isFreeCodes = false;
  //     } else {
  //       this.isFreeCodes = true;
  //       if (users.length >= MaxUsers.CapsUsers) {
  //         this.whichUser = 'forte';
  //       } else {
  //         this.whichUser = 'caps';
  //       }
  //     }
  //     let emailList: string[] = new Array();
  //     users.forEach(user => {
  //       emailList.push(user.email);
  //     });
  //     this.usedEmailList = emailList;
  //     this.isLoading = false;
  //   });
  // }

  // getCodes(): void {
  //   let subs = this.codesService.getCodes().subscribe(codes => {
  //     subs.unsubscribe();
  //     this.codes = codes;
  //   })
  // }

  // createHash(email: string): string {
  //   let hash;
  //   for (let i = 0; i < email.length; i++) {
  //     const char = email.charCodeAt(i);
  //     hash = ((hash << 5) - hash) + char; 
  //     hash = hash & hash; 
  //   }
  //   return hash;
  // }

  // apply(): void {

  //     if (this.usedEmailList.includes(this.productTesting.get('email').value) || this.usedEmailList.includes(this.createHash(this.productTesting.get('email').value))) {
  //       this.snackBar.open('Az email cím már használatban van!', 'Rendben', {
  //         duration: 5000
  //       });
  //       console.log('Az email cím már használatban van!');
  //       return;
  //     }
  //     this.getUsers();

  //     this.buttonInnerHtml =
  //       '<i class="fa fa-spinner fa-pulse fa-fw"></i><span class="sr-only">Loading...</span>';
  //     this.btnLoading = true;

  //     setTimeout(() => {
  //       const newUser: User = {
  //         name: this.productTesting.get('name').value,
  //         email: this.productTesting.get('email').value,
  //         telephone: this.productTesting.get('telephone').value,
  //         address: this.productTesting.get('address').value,
  //         privacyPolicy: this.productTesting.get('privacyPolicy').value,
  //         termsAndConditions: this.productTesting.get('termsAndConditions').value,
  //         form1: false,
  //         form2: false,
  //         registrationDate: new Date().toString(),
  //         form1Date: null,
  //         form2Date: null,
  //         whichUser: this.whichUser
  //       };
  //       let subs = this.codesService.getCodes().subscribe(codes => {
  //         subs.unsubscribe();
  //         if (!this.isFreeCodes) {
  //           return;
  //         }
  //         this.termekkiprobalasService
  //         .createUser(newUser, codes)
  //         .then((res) => {
  //           console.log('res', res.id);
  //           let user = this.termekkiprobalasService.getUser(res.id).subscribe(us => {
  //             user.unsubscribe();
  //             this.isLoading = true;
  //             this.buttonInnerHtml =
  //             '<i class="fa fa-check" aria-hidden="true"></i> Sikeres regisztráció';
  //             setTimeout(() => {
  //               this.router.navigate(["sikeres-regisztracio"]);
  //               console.log('us', us);
                
  //               this.http.post(this.endpoint, us).subscribe((response) => {
  //                 console.log(response);
  //               });
  //             }, 1500);
  //           })
  //         })
  //         .catch((err) => {
  //           console.error(err);
  //           this.buttonInnerHtml =
  //           '<i class="fa fa-times-circle" aria-hidden="true"></i> Hiba történt';
  //           setTimeout(() => {
  //             this.buttonInnerHtml =
  //             'Küldés <i class="fas fa-paper-plane"></i>';
  //             this.btnLoading = false;
  //           }, 2000);
  //         });
  //       })

  //     }, 1000);
    
  // }
}
