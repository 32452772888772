<section id="fooldal">
  <carousel
    [height]="500"
    [cellWidth]="'100%'"
    style="z-index: 1"
    [loop]="true"
    [autoplay]="true"
    [autoplayInterval]="5000"
  >
    <div class="carousel-cell">
      <div class="bg-img bg-img-2">
        <div class="bg-gradient">
          <div class="text">
            <div class="text-inner">
              <h1>Száraz, gyulladt,<br />viszkető bőr?</h1>
              <p>Mi állhat a háttérben?</p>
              <br />
              <br />
              <a [routerLink]="['szaraz-gyulladt-viszketo-bor']"
                >Az okokól bővebben
                <i class="fa fa-chevron-right fa-xs" aria-hidden="true"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="bg-img bg-img-1">
        <div class="bg-gradient">
          <div class="text">
            <div class="text-inner">
              <h1>Mi a pikkelysömör?</h1>
              <p>
                Nem is gondolnánk, de a pikkelysömör, orvosi nyelven psoriasis a
                magyar lakosság 2%-t érinti.
              </p>
              <br />
              <br />
              <a [routerLink]="['mi-a-pikkelysomor']"
                >A pikkelysömörről bővebben
                <i class="fa fa-chevron-right fa-xs" aria-hidden="true"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="bg-img bg-img-4">
        <div class="bg-gradient">
          <div class="text">
            <div class="text-inner">
              <h1>Mi az atópiás dermatitisz vagy ekcéma?</h1>
              <p>
                Az atópiás dermatitisz kialakulásában genetikai és környezeti
                tényezők játszanak szerepet.
              </p>
              <br />
              <br />
              <a [routerLink]="['mi-az-ekcema']"
                >Az ekcémáról bővebben
                <i class="fa fa-chevron-right fa-xs" aria-hidden="true"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="bg-img bg-img-3">
        <div class="bg-gradient">
          <div class="text">
            <div class="text-inner">
              <h1>A liposzómás technológia</h1>
              <p>
                Legújabb technológiai áttörések egyike a hatékonyabb
                felszívódásért.
              </p>
              <br />
              <br />
              <a [routerLink]="['liposzomalis-technologia']"
                >A liposzómás technológiáról
                <i class="fa fa-chevron-right fa-xs" aria-hidden="true"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </carousel>
  <div class="container">
    <div class="text-center">
      <h1>Az egészséges és megfelelően hidratált bőrért!</h1>
    </div>
    <div class="box-termek termek-1">
      <div class="text">
        <h2>
          Glutabalance Psorio Forte liposzómás glutation tartalmú
          étrend-kiegészítő folyadék 150 ml
        </h2>
        <ul>
          <li>Nem tartalmaz szteroidot</li>
          <li>Teljesen természetes hatóanyagok</li>
          <li>Nincs kellemetlen mellékhatás</li>
          <li>Hosszú távon, időbeli megkötések nélkül alkalmazható</li>
          <li>Napi egyszeri és gyors használat</li>
          <br />
          <br />
        </ul>
        <a [routerLink]="['glutabalance-psorio-forte']"
          >A termékről bővebben
          <i class="fa fa-chevron-right fa-xs" aria-hidden="true"></i
        ></a>
      </div>
      <div class="img">
        <img src="/assets/termekeink/termek-uj.webp" alt="" />
      </div>
    </div>
    <div class="box-termek termek-1">
      <div class="text">
        <h2>
          Glutabalance Psorio Caps glutation<br />tartalmú étrend-kiegészítő
          kapszula 30 db
        </h2>
        <ul>
          <li>Nem tartalmaz szteroidot</li>
          <li>Teljesen természetes hatóanyagok</li>
          <li>Nincs kellemetlen mellékhatás</li>
          <li>Hosszú távon, időbeli megkötések nélkül alkalmazható</li>
          <li>Napi egyszeri és gyors használat</li>
          <br />
          <br />
        </ul>
        <a [routerLink]="['glutabalance-psorio-caps']"
          >A termékről bővebben
          <i class="fa fa-chevron-right fa-xs" aria-hidden="true"></i
        ></a>
      </div>
      <div class="img">
        <img src="/assets/termekeink/termek-uj2.webp" alt="" />
      </div>
    </div>

    <div class="fooldal-box ekcema-box">
      <div class="text" style="order: 2; text-align: right">
        <h2>Mi az az ekcéma?</h2>
        <div class="text-box">
          <p>
            Az atópiás dermatitisz vagy ekcéma egy krónikus lefolyású,
            folyamatos fellángolásokkal járó, nem fertőző, gyulladásos
            bőrbetegség, amely jelentősen rontja az életminőséget.
          </p>
          <br />
          <br />
          <a [routerLink]="['mi-az-ekcema']"
            >Az ekcémáról bővebben
            <i class="fa fa-chevron-right fa-xs" aria-hidden="true"></i
          ></a>
        </div>
      </div>
      <div class="img" style="order: 1">
        <img src="/assets/fooldal/box5.webp" alt="" />
      </div>
    </div>

    <div class="fooldal-box pikkelysomor-box">
      <div class="text">
        <h2>Mi a pikkelysömör?</h2>
        <div class="text-box">
          <p>
            A pikkelysömör, orvosi nyelven psoriasis egy autoimmun betegség,
            amely leggyakrabban a végtagokon és a hajas fejbőrön jelenik meg.
          </p>
          <br />
          <br />
          <a [routerLink]="['mi-a-pikkelysomor']"
            >A pikkelysömörről bővebben
            <i class="fa fa-chevron-right fa-xs" aria-hidden="true"></i
          ></a>
        </div>
      </div>
      <div class="img">
        <img src="/assets/fooldal/box1.webp" alt="" />
      </div>
    </div>

    <div class="fooldal-box hatoanyag-box">
      <div class="text" style="order: 2; text-align: right">
        <h2>Hogyan hatnak a Glutabalance termékei?</h2>
        <div class="text-box">
          <p>
            Új portfólió természetes, méregtelenítő hatóanyagokkal a
            pikkelysömör kezelés esetén.
          </p>
          <br />
          <br />
          <a [routerLink]="['a-glutabalance-hatasai']"
            >A Glutabalance hatásairól bővebben
            <i class="fa fa-chevron-right fa-xs" aria-hidden="true"></i
          ></a>
        </div>
      </div>
      <div class="img" style="order: 1">
        <img src="/assets/fooldal/box2.webp" alt="" />
      </div>
    </div>

    <div class="fooldal-box liposzomas-box">
      <div class="text">
        <h2>A liposzómás technológia</h2>
        <div class="text-box">
          <p>
            A liposzómás technológia a legújabb technológiai áttörések egyike,
            melyet az orvos-kutatók a fontos hatóanyagok megfelelő szervekhez
            történő eljuttatására alkalmaznak.
          </p>
          <br />
          <br />
          <a [routerLink]="['liposzomalis-technologia']"
            >A liposzómás technológiáról bővebben
            <i class="fa fa-chevron-right fa-xs" aria-hidden="true"></i
          ></a>
        </div>
      </div>
      <div class="img">
        <img src="/assets/fooldal/box3.webp" alt="" />
      </div>
    </div>
  </div>
</section>

<section id="footer">
  <div class="footer-top">
    <div class="container">
      <small
        >A Glutabalance Psorio Forte és a Glutabalance Psorio Caps
        étrend-kiegészítő termékek.<br />Étrend-kiegészítő, nem helyettesíti a
        vegyes étrendet és az egészséges életmódot.</small
      >
      <div class="action-box">
        <h3>Kipróbálom a Glutabalance termékeit!</h3>
        <a [routerLink]="['/vasarlasi-informaciok']"
          >Tovább <i class="fa fa-chevron-right fa-xs" aria-hidden="true"></i
        ></a>
      </div>
    </div>
  </div>
</section>
