<section id="termekkiprobalas">
  <ng-container *ngIf="isLoadingPsw">
    <div style="width: 100%">
      <mat-spinner style="margin: auto"></mat-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!isLoadingPsw && isRequiredPsw">
    <div style="text-align: center;">
      <form (ngSubmit)="checkPsw(inputData)">
        <input type="text" [(ngModel)]="inputData" [ngModelOptions]="{standalone: true}" style="display: block; margin: 1rem auto;">
        <button type="submit">Belépés</button>
      </form>
    </div>
  </ng-container>
  <ng-container *ngIf="!isRequiredPsw">

    <div class="container">
      <h1>Egy hónapos termék kipróbálás</h1>
      <div class="d-flex" *ngIf="!isLoading">
        <!-- <div class="d-flex" *ngIf="isFreeCodes && !isLoading"> -->
          <div class="text-box">
            

            <p>Ezen az oldalon tud jelentkezni a Glutabalance termékeinek kipróbálására, melynek során cégünk biztostja Ön számára az ingyenes termék-mintát.</p>
            <p>A kipróbálást követően nem kérünk mást Öntől, csak hogy ossza meg velünk a tapasztalatát a készítményről!</p>
            <p>A felmérés folyamata a következő:</p>
            <ol>
              <li>Nem kell mást tenni, mint  kitölteni a jelentkezési felületet a nevével, postai és email címével, amely alapján mi regisztrálni tudjuk Önt rendszerünkben.</li>
              <li>A jelentkezés feldolgozása után kapni fog a jelentkező egy linket a tünetek előzetes felméréséről.</li>
              <li>Amint az előzetes kérdéseket kitöltötte és elküldte, egy 2-4 hetes kúrára elegendő terméket postázunk a megadott postai címére. Mivel több termékváltozatot is tesztelünk a kúra hossza egyénileg változat, attól függően, hogy melyik csoportba kerül.</li>
            </ol>
            <p>A kúra leteltével kapni fog egy újabb linket emailben, melyben a termékkel kapcsolatos tapasztatairól szóló kérdőív kitöltésére kérjük, mely mindösszesen 6 kérdésből áll (maximum 1 perc).</p>
            
          </div>
          
          <div class="form">
            <h2>
              Jelentkezzen a Glutabalance termékeinek kipróbálására!
            </h2>
            <form (ngSubmit)="apply()" [formGroup]="productTesting">
              <div class="form-group">
                <label for="giveawayName">Név*</label>
                <input
                type="text"
                id="giveawayName"
                name="giveawayName"
                formControlName="name"
                />
              </div>
              <div class="form-group">
                <label for="giveawayEmail">E-mail cím*</label>
                <input
                type="email"
                id="giveawayEmail"
                name="giveawayEmail"
                formControlName="email"
                />
              </div>
              <div class="form-group">
                <label for="telephone">Telefonszám*</label>
                <input
                type="tel"
                id="telephone"
                name="telephone"
                formControlName="telephone"
                />
              </div>
              <div class="form-group">
                <label for="giveawayAddress">Postai cím*</label>
                <input
                type="text"
                id="giveawayAddress"
                name="giveawayAddress"
                formControlName="address"
                />
                <strong
                ><small
                >Kérjük olyan címet adjon meg, ahol napközben tartózkodik és el
                tudja érni Önt a futárunk!</small
                ></strong
                >
            <strong
            ><small
                >Figyelem! Csak magyarországi cím megadása lehetséges!</small
                ></strong
                >
              </div>
              <div class="form-group">
                <input
                type="checkbox"
                id="giveawayPrivacyPolicy"
                name="privacyPolicy"
                formControlName="privacyPolicy"
                [checked]="productTesting.get('privacyPolicy').value"
                />
                <label for="giveawayPrivacyPolicy" class="privacyPolicyLabel">
                  Kijelentem, hogy az
                  <a
                  href="/assets/adatkezelesi-tajekoztato.pdf"
                  target="_blank"
                  style="text-decoration: underline; color: #3d1e58"
                  >Adatkezelési tájékoztatóban</a
                  >
                  foglaltakat teljes körűen megismertem és megértettem, és az abban
                  foglaltak szerint önkéntesen hozzájárulok a személyes adataim
                  kezeléséhez. *</label
                  ><br />
                </div>
                <div class="form-group">
                  <input
                  type="checkbox"
                  id="giveawayRules"
                  name="privacyPolicy"
                  formControlName="termsAndConditions"
                  [checked]="productTesting.get('termsAndConditions').value"
                  />
                  <label for="giveawayRules" class="privacyPolicyLabel">
                    Kijelentem, hogy részt kívánok venni a termékkipróbáláson. *</label
                    ><br />
                  </div>
                  <button
                  [innerHTML]="buttonInnerHtml"
                  type="submit"
                  class="submit"
                  [disabled]="!productTesting.valid || btnLoading"
                  ></button>
                </form>
              </div>
            </div>
          </div>
          <!-- <div *ngIf="true">
            <div class="container">
      <div class="d-flex max-576">
        <div class="text-box">
          <h2>
            A Glutabalance termékeinek kipróbálásával kapcsolatos aktivitásra
            sajnos elfogytak a helyek.
          </h2>
        </div>
      </div>
    </div>
  </div> -->
  
  <!-- <div *ngIf="!isFreeCodes && !isLoading">
    <div class="container">
      <div class="d-flex max-576">
        <div class="text-box">
          A Glutabalance termékeinek kipróbálásával kapcsolatos aktivitásra
          sajnos elfogytak a helyek.
        </div>
      </div>
    </div>
  </div> -->
  <div *ngIf="isLoading">
    <div style="width: 100%">
      <mat-spinner style="margin: auto"></mat-spinner>
    </div>
  </div>
</ng-container>
</section>
