<div id="grey-bg" (click)="closeMenu()"></div>
<nav id="navbar">
  <div class="brand">
    <a [routerLink]="['']" routerLinkActive="router-link-active">
      <img src="/assets/logo.webp" alt="" />
    </a>
  </div>
  <div class="ml-auto">
    <ul>
      <li>
        <a class="dropdown" [matMenuTriggerFor]="productsMenu">Termékeink</a>
        <mat-menu #productsMenu="matMenu">
          <a mat-menu-item [routerLink]="['/glutabalance-psorio-forte']"
            >Glutabalance Psorio Forte</a
          >
          <a mat-menu-item [routerLink]="['/glutabalance-psorio-caps']"
            >Glutabalance Psorio Caps</a
          >
        </mat-menu>
      </li>
      <li>
        <a [routerLink]="['/velemenyek']">Vélemények</a>
      </li>
      <li>
        <a [routerLink]="['/szaraz-gyulladt-viszketo-bor']"
          >Száraz, gyulladt,<br />viszkető bőr?</a
        >
      </li>
      <li>
        <a [routerLink]="['/a-glutabalance-hatasai']"
          >Hogyan hatnak a<br />Glutabalance termékei?</a
        >
      </li>
      <li>
        <a [routerLink]="['/liposzomalis-technologia']"
          >Liposzómás technológia</a
        >
      </li>
      <li>
        <a [routerLink]="['/vasarlasi-informaciok']">Vásárlási információk</a>
      </li>
      <li>
        <a [routerLink]="['/kapcsolat']">Kapcsolat</a>
      </li>
    </ul>
  </div>
</nav>
<nav class="navbar-mobile" role="navigation">
  <div id="menuToggle">
    <input
      type="checkbox"
      (click)="openMenu()"
      [(ngModel)]="navbarCheckbox"
      [ngModelOptions]="{ standalone: true }"
    />
    <span></span>
    <span></span>
    <span></span>
    <ul id="menu">
      <li class="dropdown">
        <span class="dd-title" (click)="isDropdownOpen = !isDropdownOpen"
          ><i
            class="fa fa-chevron-right"
            [ngStyle]="{ transform: isDropdownOpen ? 'rotate(90deg)' : 'none' }"
            aria-hidden="true"
          ></i>
          Termékeink</span
        >
        <div
          class="dropdown-items"
          [ngStyle]="{ display: isDropdownOpen ? 'block' : 'none' }"
        >
          <ul>
            <li>
              <a (click)="navigate('glutabalance-psorio-forte')"
                >Glutabalance Psorio Forte</a
              >
            </li>
            <li>
              <a (click)="navigate('glutabalance-psorio-caps')"
                >Glutabalance Psorio Caps</a
              >
            </li>
          </ul>
        </div>
      </li>

      <li><a (click)="navigate('velemenyek')">Vélemények</a></li>
      <li>
        <a (click)="navigate('szaraz-gyulladt-viszketo-bor')"
          >Száraz, gyulladt, viszkető bőr?</a
        >
      </li>
      <li>
        <a (click)="navigate('a-glutabalance-hatasai')"
          >Hogyan hatnak a Glutabalance termékei?</a
        >
      </li>
      <li>
        <a (click)="navigate('liposzomalis-technologia')"
          >Liposzómás technológia</a
        >
      </li>
      <li>
        <a (click)="navigate('vasarlasi-informaciok')">Vásárlási információk</a>
      </li>
      <li><a (click)="navigate('kapcsolat')">Kapcsolat</a></li>
    </ul>
  </div>
  <div class="logo">
    <img (click)="navigate('')" src="/assets/logo.webp" alt="" />
  </div>
</nav>
