import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FooldalComponent } from "./pages/fooldal/fooldal.component";
import { TermekeinkComponent } from "./pages/termekeink/termekeink.component";
import { MiAPikkelysomorComponent } from "./pages/mi-a-pikkelysomor/mi-a-pikkelysomor.component";
import { GlutabalanceHatasaiComponent } from "./pages/glutabalance-hatasai/glutabalance-hatasai.component";
import { LiposzomalisTechnologiaComponent } from "./pages/liposzomalis-technologia/liposzomalis-technologia.component";
import { VasarlasiInformaciokComponent } from "./pages/vasarlasi-informaciok/vasarlasi-informaciok.component";
import { KapcsolatComponent } from "./pages/kapcsolat/kapcsolat.component";
import { GlutabalancePsorioforteComponent } from "./pages/termekeink/glutabalance-psorioforte/glutabalance-psorioforte.component";
import { GlutabalancePsoriocapsComponent } from "./pages/termekeink/glutabalance-psoriocaps/glutabalance-psoriocaps.component";
import { TermekkiprobalasComponent } from "./pages/termekkiprobalas/termekkiprobalas.component";
import { SikeresRegisztracioComponent } from "./pages/sikeres-regisztracio/sikeres-regisztracio.component";
import { KoszonjukComponent } from "./pages/koszonjuk/koszonjuk.component";
import { QuestionnaireComponent } from "./redirects/questionnaire/questionnaire.component";
import { TestimonialComponent } from "./pages/testimonial/testimonial.component";
import { MiAzEkcemaComponent } from "./pages/mi-az-ekcema/mi-az-ekcema.component";
import { SzarazGyulladtViszketoBorComponent } from "./pages/szaraz-gyulladt-viszketo-bor/szaraz-gyulladt-viszketo-bor.component";

const routes: Routes = [
  {
    path: "",
    component: FooldalComponent,
  },
  {
    path: "velemenyek",
    component: TestimonialComponent,
  },
  {
    path: "termekeink",
    component: TermekeinkComponent,
  },
  {
    path: "glutabalance-psorio-forte",
    component: GlutabalancePsorioforteComponent,
  },
  {
    path: "glutabalance-psorio-caps",
    component: GlutabalancePsoriocapsComponent,
  },
  {
    path: "szaraz-gyulladt-viszketo-bor",
    component: SzarazGyulladtViszketoBorComponent,
  },
  {
    path: "mi-a-pikkelysomor",
    component: MiAPikkelysomorComponent,
  },
  {
    path: "mi-az-ekcema",
    component: MiAzEkcemaComponent,
  },
  {
    path: "a-glutabalance-hatasai",
    component: GlutabalanceHatasaiComponent,
  },
  {
    path: "liposzomalis-technologia",
    component: LiposzomalisTechnologiaComponent,
  },
  {
    path: "vasarlasi-informaciok",
    component: VasarlasiInformaciokComponent,
  },
  {
    path: "kapcsolat",
    component: KapcsolatComponent,
  },
  {
    path: "koszonjuk-a-levelet",
    component: KoszonjukComponent,
  },
  {
    path: "koszonjuk-a-velemenyet",
    component: KoszonjukComponent,
  },
  // {
  //   path: "termekkiprobalas",
  //   component: TermekkiprobalasComponent,
  // },
  // {
  //   path: "sikeres-regisztracio",
  //   component: SikeresRegisztracioComponent,
  // },
  // {
  //   path: 'masodik-kerdoiv-311/:id',
  //   component: QuestionnaireComponent
  // },
  // {
  //   path: 'masodik-kerdoiv-310/:id',
  //   component: QuestionnaireComponent
  // },
  // {
  //   path: 'kupon/:id',
  //   component: QuestionnaireComponent
  // },

  {
    path: "**",
    redirectTo: "/",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
