import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {

  constructor(
    private firestore: AngularFirestore
  ) {
  }

  createQuestionnaireLog(id: string, type: string) {
    const questionnaire = {
      id: id,
      type: type,
      date: new Date()
    };
    return this.firestore.collection('questionnaireLog').add(questionnaire);
  }

  createCouponLog(id: string, type: string) {
    const questionnaire = {
      id: id,
      type: type,
      date: new Date()
    };
    return this.firestore.collection('couponLog').add(questionnaire);
  }
}
