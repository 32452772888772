<section id="sikeres-regisztracio">
    <div class="container">
        <div class="wrapper">
            <div class="box">
                <h1>Sikeres regisztráció!</h1>
                <p>Köszönjük, hogy regisztrált, bízunk benne, hogy termékünk segítségével hatékonyan tudunk a terápiája alatt segíteni Önnek.</p>
                <p>Kérjük, hogy figyelje a megadott e-mail címének bejövő leveleit, mert ebben fog találni egy leírást a termék kipróbálás pontos menetéről, valamint itt fogja megtalálni az első kérdőív linkjét, amely annak feltétele, hogy el tudjuk Önnek küldeni a kúrához tartozó terméket.</p>
            </div>
        </div>
    </div>
</section>