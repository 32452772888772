import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {

  linkCaps = 'https://cloud.capitris.hu/ffr/survey/F_311?uid=';
  linkForte = 'https://cloud.capitris.hu/ffr/survey/F_310?uid=';
  linkCoupon = 'https://www.vitalweb.hu/glutabalance';

  constructor(
    private router: Router,
    private questionnaireService: QuestionnaireService
  ) { }

  ngOnInit(): void {
    const url = this.router.url.split('/');
    
    if (url[1] === 'masodik-kerdoiv-311') {
      this.questionnaireService.createQuestionnaireLog(url[2], 'caps').then(() => {
        window.location.href = this.linkCaps + url[2];
      }).finally(() => {
        window.location.href = this.linkCaps + url[2];
      })
    }
    
    if (url[1] === 'masodik-kerdoiv-310') {
      this.questionnaireService.createQuestionnaireLog(url[2], 'forte').then(() => {
        window.location.href = this.linkForte + url[2];
      }).finally(() => {
        window.location.href = this.linkForte + url[2];
      })
    }
    
    if (url[1] === 'kupon') {
      this.questionnaireService.createCouponLog(url[2], 'kupon').then(() => {
        window.location.href = this.linkCoupon;
      }).finally(() => {
        window.location.href = this.linkCoupon;
      })
    }
  }

}
