<section id="tabs">
  <div class="container">
    <div class="tab-box">
      <mat-tab-group mat-align-tabs="start" class="tabs">
        <mat-tab label="Termékinformáció">
          <div class="tab">
            <h2>{{ title }}</h2>
            <div class="overflow-x">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th>Hatóanyag</th>
                    <th>mg hatóanyag</th>
                    <th>NRV%</th>
                  </tr>
                  <tr>
                    <td></td>
                    <td>{{ subtitle }}</td>
                    <td>napi adagban</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of table">
                    <th>{{ row.col1 }}</th>
                    <td>{{ row.col2 }}</td>
                    <td>{{ row.col3 }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <small>*NRV%= Ajánlott Napi Tápanyagbevitel %</small>
            <ng-container *ngIf="ingredients.length > 0">
              <h2 class="osszetevok">Összetevők:</h2>
              <ul>
                <li *ngFor="let ingredient of ingredients">
                  {{ ingredient }}
                </li>
              </ul>
            </ng-container>
          </div>
        </mat-tab>
        <mat-tab label="Adagolás">
          <div class="tab tab2">
            <h2>Adagolás</h2>
            <ul class="adagolas">
              <li *ngFor="let item of dosage">
                <img [src]="item.imgUrl" alt="" />
                <span>{{ item.text }}</span>
              </li>
            </ul>
            <h2>Tárolás</h2>
            <ul class="tarolas">
              <li *ngFor="let item of storage">
                <img [src]="item.imgUrl" alt="" />
                <span>{{ item.text }}</span>
              </li>
            </ul>
          </div>
        </mat-tab>
        <mat-tab label="Fogyasztói visszajelzések">
          <div class="tab">
            <h2>Fogyasztói visszajelzések</h2>
            <!-- <div class="overflow-y">
                            <ng-container *ngFor="let feedbackMsg of feedback">
                                <div class="feedback">
                                    <div class="feedback-header">
                                        <strong class="name">{{ feedbackMsg.name }}</strong>
                                        <div class="stars">
                                            <ng-container *ngFor="let star of feedbackMsg.stars">
                                                <i class="fas fa-star" *ngIf="star"></i>
                                                <i class="far fa-star" *ngIf="!star"></i>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <p [innerHTML]="feedbackMsg.feedbackMsg"></p>
                                </div>
                            </ng-container>
                        </div> -->
            <div class="text-center">
              <a
                [routerLink]="['/kapcsolat']"
                fragment="velemeny"
                routerLinkActive="router-link-active"
                >Ossza meg véleményét!
                <i class="fa fa-chevron-right fa-xs" aria-hidden="true"></i
              ></a>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>

      <div class="mobile-tabs">
        <div class="tab">
          <h2>Hatóanyagok és összetevők 5 ml-ben</h2>
          <div class="overflow-x">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th>Hatóanyag</th>
                  <th>mg hatóanyag</th>
                  <th>NRV%</th>
                </tr>
                <tr>
                  <td></td>
                  <td>5 ml napi adagban</td>
                  <td>napi adagban</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of table">
                  <th>{{ row.col1 }}</th>
                  <td>{{ row.col2 }}</td>
                  <td>{{ row.col3 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <small>*NRV%= Ajánlott Napi Tápanyagbevitel %</small>
          <ng-container *ngIf="ingredients.length > 0">
            <h2 class="osszetevok">Összetevők:</h2>
            <ul>
              <li *ngFor="let ingredient of ingredients">
                {{ ingredient }}
              </li>
            </ul>
          </ng-container>
        </div>
        <div class="tab tab2">
          <h2>Adagolás</h2>
          <ul class="adagolas">
            <li *ngFor="let item of dosage">
              <img [src]="item.imgUrl" alt="" />
              <span>{{ item.text }}</span>
            </li>
          </ul>
          <h2>Tárolás</h2>
          <ul class="tarolas">
            <li *ngFor="let item of storage">
              <img [src]="item.imgUrl" alt="" />
              <span>{{ item.text }}</span>
            </li>
          </ul>
        </div>
        <div class="tab">
          <h2>Fogyasztói visszajelzések</h2>
          <!-- <div class="overflow-y">
                        <ng-container *ngFor="let feedbackMsg of feedback">
                            <div class="feedback">
                                <div class="feedback-header">
                                    <strong class="name">{{ feedbackMsg.name }}</strong>
                                    <div class="stars">
                                        <ng-container *ngFor="let star of feedbackMsg.stars">
                                            <i class="fas fa-star" *ngIf="star"></i>
                                            <i class="far fa-star" *ngIf="!star"></i>
                                        </ng-container>
                                    </div>
                                </div>
                                <p [innerHTML]="feedbackMsg.feedbackMsg"></p>
                            </div>
                        </ng-container>
                    </div> -->
          <div class="text-center">
            <a
              [routerLink]="['/kapcsolat']"
              fragment="velemeny"
              routerLinkActive="router-link-active"
              >Ossza meg véleményét!
              <i class="fa fa-chevron-right fa-xs" aria-hidden="true"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
